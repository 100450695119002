 @font-face {
    font-family: 'Cupra';
    src: local( 'Cupra' ),
    local( 'Cupra-Medium' ),
    url( './fonts/Cupra-Medium.woff' ) format( 'woff' );
    font-weight: 500;
    font-style: normal;
 }

 @font-face {
   font-family: 'Cupra';
   src: local( 'Cupra' ),
   local( 'Cupra-Book' ),
   url( './fonts/Cupra-Book.woff' ) format( 'woff' );
   font-weight: 300;
   font-style: normal;
 }
