@import 'assets/styles/_mixins.scss';
@import 'assets/styles/_variables.scss';
@import 'assets/fonts.scss';

html {
    height: 100%;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    font-family: 'Seat Bcn', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

// STORYBOOK canvas
.sb-show-main {
    background-color: $color-white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@media print {
    @page {
        size: auto;
        margin: 0;
    }

    .content-wrapper {
        p,
        li {
            font-size: 12px;
        }

        h3 {
            font-size: 14px;
        }

        h1 {
            font-size: 18px;
        }
    }

    .pb-before {
        page-break-before: always !important;
    }
    .noprint {
        display: none;
    }
}
