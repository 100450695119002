//== Colors
$color-secondary: #ea5d1a;

$color-black: #000;
$color-black-light: #2b2b2b;

$color-service-background: rgb(56, 56, 56);

$color-black-lower-transparent: rgba(0, 0, 0, 0.9);
$color-black-low-transparent: rgba(0, 0, 0, 0.7);
$color-black-medium-transparent: rgba(0, 0, 0, 0.85);
$color-black-medium-high-transparent: rgba(0, 0, 0, 0.5);
$color-black-high-transparent: rgba(0, 0, 0, 0.4);
$color-black-extreme-high-transparent: rgba(0, 0, 0, 0.1);
$color-black-very-extreme-high-transparent: rgba(0, 0, 0, 0.05);

$default-radius: 22.5px;

$color-gray: #b4b4b4;
$color-gray-very-light: #f3f3f3;
$color-gray-light: #e5e5e5;
$color-gray-mid: #e6e6e6;
$color-gray-mid-dark: #dfdedf;
$color-gray-mid-darker: #afafaf;
$color-gray-dark: #787878;
$color-greyish-brown: #4a4a4a;
$color-greyish-brown-transparent: rgba(74, 74, 74, 0.2);
$color-gray-dark-mild: #373737;
$color-gray-very-dark: #404040;
$color-gray-very-dark-mild: #383838;
$color-gray-button-secondary-border: #c0c0c0;
$color-gray-button-secondary: #4b4a4b;

$color-white: #fff;
$color-white-grayer: #cbcbcb;
$color-white-transparent: rgba(255, 255, 255, 0.3);
$color-white-low-transparent: rgba(255, 255, 255, 0.9);

$color-green: #4ba82e;
$color-green-dark: #377220;
$color-green-hover: #419128;

$color-red: #bd1823;
$color-red-dark: #89121e;
$color-red-light: #ec1e1e;

$color-orange: #f39200;

$color-yellow: #ffec00;
$color-yellow-dark: #ccb800;
$color-yellow-light: #e8c100;
$color-orange: #f5a623;

$color-blue: #398bf2;
$color-disabled: #b8b8b8;

$color-default-input: #c8cbcd;
$color-disabled-button: #b8b8b8;
$color-disabled-button-background: #dcdcdc;
$color-disabled-dark-button-background: rgba(255, 255, 255, 0.1);
$color-disabled-value: rgba(255, 255, 255, 0.2);
$color-disabled-label: rgba(255, 255, 255, 0.3);
$color-disabled-selectbox-value: rgba(184, 184, 184, 0.5);

$color-soft-red: #ea5167;
$color-moderate-cyan: #5caba6;
$color-moderate-cyan-light: #76b4af;
$color-moderate-cyan-dark: #42a29c;

$color-table-nodata: #919191;
$color-table-record: #404040;

$color-rts-history-shadow: rgba(56, 56, 56, 0.97);
$color-rts-history-whiter-text: #dedede;

$color-map-zoom-controls-shadow: #b1b1b1;

$color-poi-nodata: #919191;

$button-shadow: 0 2px 4px 0 rgba(65, 145, 40, 0.27),
    0 6px 12px 0 rgba(65, 145, 40, 0.2);

$font-family-sans-serif: 'Seat Bcn';
$font-family-serif: 'Seat Bcn';
$font-family-monospace: 'Seat Bcn';
$font-family-base: 'Seat Bcn';

$color-gray-separator: rgba(184, 184, 184, 0.4);

$color-input-label: #808285;
