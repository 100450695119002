@font-face {
    font-family: 'Seat Bcn';
    src: local( 'Seat Bcn Regular' ),
    local( 'SeatBcn-Regular' ),
    url( './fonts/SeatBcn-Regular.woff' ) format( 'woff' );
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Seat Bcn';
    src: local( 'Seat Bcn Medium' ),
    local( 'SeatBcn-Medium' ),
    url( './fonts/SeatBcn-Medium.woff' ) format( 'woff' );
    font-weight: 500;
}

@font-face {
    font-family: 'Seat Bcn';
    src: local( 'Seat Bcn Bold' ),
    local( 'SeatBcn-Bold' ),
    url( './fonts/SeatBcn-Bold.woff' ) format( 'woff' );
    font-weight: 700;
}

@font-face {
  font-family: 'Seat Bcn';
  src: local( 'Seat Bcn Black' ),
  local( 'SeatBcn-Black' ),
  url( './fonts/SeatBcn-Black.woff' ) format( 'woff' );
  font-weight: 900;
}
