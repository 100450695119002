@import 'src/assets/styles/variables';

.bracket-list {
    counter-reset: list;
    margin: 0;

    > li {
        margin-top: 15px;
        list-style: none;
        position: relative;
    }

    > li:before {
        counter-increment: list;
        content: counter(list) ') ';
        position: absolute;
        left: -1.4em;
    }
}

.dot-list {
    > li {
        margin-top: 10px;
    }
}

.signature {
    border: 0;
    border-bottom: 1px solid #000;
    margin-left: 20px;
    width: 250px;
    background-color: transparent;
}

.content-wrapper {
    width: 80%;
    margin: 80px auto 0;
    font-size: 1rem;
    line-height: 1.5rem;

    &--print {
        width: 210mm; // for printing purposes
    }
}

.new-page {
    height: 80px;
}
